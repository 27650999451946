import React from "react";

function Home() {
  return (
    <>
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Coflow - Property Management Made Simple</title>

        {/* Meta SEO */}
        <meta name="title" content="Coflow - Property Management Simplified" />
        <meta
          name="description"
          content="Streamlining property maintenance requests with AI to triage and solve issues efficiently."
        />
        <meta name="robots" content="index, follow" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      </head>

      <body className="min-h-screen flex flex-col">
        {/* Header */}
        <header className="flex justify-between items-center py-6 px-8">
          <div className="flex items-center">
            <img src="/favicon.png" alt="Coflow Logo" className="h-6 mr-3" />
            <span className="text-2xl font-semibold">Coflow</span>
          </div>
        </header>

        {/* Header and Subheader Section */}
        <main className="flex-grow flex flex-col justify-center items-center text-center px-8">
          <h1 className="text-4xl font-bold mb-6">
            Triage Property Maintenance{" "}
            <span className="underline">with Ease</span>
          </h1>
          <p className="text-gray-600 mb-8 text-lg leading-relaxed">
            Not all issues are equal. Our AI guides tenants through troubleshooting, resolving issues up front and de-escalating emergencies.
            <br />
            From resetting a fuse to checking a lightbulb, we help solve issues fast, minimizing unnecessary requests.
          </p>
        </main>

        {/* Footer */}
        <footer className="w-full py-4 bg-gray-100 text-center">
          <span className="block text-sm text-gray-500">
            alexander@joincoflow.com
          </span>
        </footer>
      </body>
    </>
  );
}

export default Home;
